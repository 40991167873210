let base_app_url = "https://app.patma.co.uk/";
if (window.location.host.indexOf('127.0.0.1') >= 0) {
    base_app_url = 'http://127.0.0.1:8000/';
} else if (window.location.host.indexOf('web.staging.patma.co.uk') >= 0) {
    base_app_url = 'https://staging.patma.co.uk/';
}
for (let form_parent of document.getElementsByClassName('mailing-form')) {
    for (let form of form_parent.getElementsByTagName('form')) {
        let form_errors = form.querySelector('.form-errors');
        form.addEventListener("submit", (e) => {
            e.preventDefault();
            form_errors.replaceChildren();
            let data = new FormData(form);
            data.set('signup', '');
            fetch(base_app_url + "profile/form_signup", {
                method: "POST",
                body: data
            }).then(function(res) {
                return res.json();
            }).then(function(data) {
                if (data.success) {
                    form.innerHTML = '<div class="text-success text-center">Thank you. You have successfully signed up.</div>';
                } else if (data.errors) {
                    for (let e in data.errors) {
                        for (let m of data.errors[e]) {
                            form_errors.append(m.message);
                        }
                    }
                } else if (data.not_allowed) {
                    form_errors.append('Sorry, this is not allowed.');
                } else {
                    form_errors.append("Sorry, your request could not be completed.");
                }
            }).catch(err => {
                console.error(err);
                form_errors.append("Sorry, something went wrong.");
            });
        });
    }
}
